<template>
  <!-- 虚拟仿真实验成绩 -->
  <div class="scoretable">
    <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" class="Table"
      :header-cell-style="{
        'font-size': '15px',
        color: '#666666',
        'font-weight': 'bold',
        background: '#F7F7F7',
      }" :row-style="{
        'font-size': '15px',
        color: '#222222',
        'font-weight': '400',
      }">
      <el-table-column prop="module_name" label="实验名称"> </el-table-column>
      <el-table-column prop="start_time" label="实验开始时间">
        <template slot-scope="scope">
          {{ scope.row.start_time | dataformat }}
        </template>
      </el-table-column>
      <el-table-column prop="end_time" label="实验结束时间" style="">
        <template slot-scope="scope">
          {{ scope.row.end_time | dataformat }}
        </template>
      </el-table-column>
      <el-table-column prop="duration" label="实验用时(分钟)" align="center">
        <template slot-scope="scope">
          {{ scope.row.duration }}
        </template>
      </el-table-column>

      <el-table-column label="步骤成绩" align="center">
        <template slot-scope="scope">
          <div class="item-right">
            <div class="bt" @click="resultsbt(scope.row)">
              {{ scope.row.score }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="报告状态" align="center">
        <template slot-scope="scope">
          <div class="item-right">
            <div v-if="scope.row.report_status === 1">
              已提交
            </div>
            <div v-if="scope.row.report_status == -1 || scope.row.report_status == 0">
              未提交
            </div>
            <div v-if="scope.row.report_status === 2">
              已批阅
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="报告成绩" align="center">
        <template slot-scope="scope">
          <div class="item-right">
            {{ scope.row.report_score }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <div class="item-right">
            <div class="bt" v-if="scope.row.report_status == 1 || scope.row.report_status == 2"
              @click="viewreport(scope.row)">
              查看
            </div>
            <div class="bt" v-if="scope.row.report_status == -1" @click="newadd(scope.row,'1')">
              新增
            </div>
            <div class="bt" v-if="scope.row.report_status == 0" @click="newadd(scope.row,'2')">
              编辑
            </div>
          </div>
        </template>
      </el-table-column>
      <el-dialog width="40%" title="实验报告查看" class="customdialog" :visible.sync="dialogTableVisible" append-to-body>
        <div class="dialogcontain">
          <div class="sectioncontain">
            <div class="title">实验标题:</div>
            <div class="content">
              {{ itemdetail.title }}
            </div>
          </div>
          <div class="sectioncontain dialogmain">
            <div class="title">实验报告:</div>
            <div class="content">
              <div v-html="itemdetail.content"></div>
              <div class="annex">
                附件：
                <el-button type="text" v-if="itemdetail.file" @click="seeFiles(itemdetail.file[0].url)">
                  查看
                </el-button>
                <!-- <el-button type="text" v-if="itemdetail.file.length > 0" @click="downFiles(itemdetail.file[0].url)">
                  下载
                </el-button> -->
              </div>
            </div>
          </div>
          <div class="sectioncontain">
            <div class="title">报告评语:</div>
            <div class="content">{{itemdetail.reply}}</div>
          </div>
          <div class="sectioncontain">
            <div class="title">报告评分:</div>
            <div class="content">{{itemdetail.score}}</div>
          </div>
        </div>
      </el-dialog>
    </el-table>
    <div class="elpagination">
      <div class="elpagination-title">共{{ total }}条</div>
      <el-pagination background layout="prev, pager, next" :total="total" :page-size="10" :current-page="form.page"
        @current-change="pageChange">
      </el-pagination>
    </div>

    <Customdialog ref="customdialog" title="虚拟仿真实验成绩" class="custominnerdialog" type="max" :showclose="true"
      :appendtobody="true" min-width="60%">
      <div slot="headtitle" class="innerheadtitle">实验步骤</div>
      <div slot="dialogbody" class="innerdialogbody">
        <div class="title"> {{ info.module_name ? info.module_name : "模块标题" }}</div>
        <div class="head">
          <div class="item">
            <span>总分:&emsp;100</span>
            <span>得分:&emsp;{{ info.score }}</span>
          </div>
          <div class="item">
            <span>
              操作时间:&emsp; {{ info.start_time | dataformat }} ~ {{ info.end_time | dataformat }}
            </span>
          </div>
          <div class="item">操作时长:&emsp;{{ info.duration }}分钟</div>
        </div>
        <div class="content" style="height: 500px; overflow-y: scroll;">
          <div class="content_title">实验步骤</div>
          <div class="content_info" v-for="(item, index) in stepInfo" :key="index">
            <div class="content_info_item">
              <div class="info_item_title">
                {{ index * 1 + 1 }}.{{ item.title }}
              </div>
              <div class="info_item_body">
                <div>操作次数:&emsp;{{ item.repeat_count }}</div>
                <div>
                  操作时间:&emsp;{{ item.start_time | dataformat }} ~ {{ item.end_time | dataformat }}
                </div>
                <div>总分:&emsp;{{ item.max_score }}</div>
                <div>得分:&emsp;{{ item.score }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Customdialog>
    <!-- <el-dialog title="实验报告" :visible.sync="dialogTableVisible">
      <el-table :data="gridData">
        <el-table-column property="date" label="日期" width="150"></el-table-column>
        <el-table-column property="name" label="姓名" width="200"></el-table-column>
        <el-table-column property="address" label="地址"></el-table-column>
      </el-table>
    </el-dialog> -->
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getmyvrstudylist, getStepList } from "@/api/vrstudy";
import Customdialog from "@/components/customdialog.vue";
import {
  getvrstudyreportinfo,
} from "@/api/vrstudyreport";
export default {
  name: "virtualsimulation",
  components: {
    Customdialog,
  },
  data() {
    return {
      total: 0,
      tableData: [],
      itemdetail:"",
      info: {},
      stepInfo: {},
      form: {
        vr_id: '',
        page: 1,
        per_page: 10
      },
      dialogTableVisible: false
    };
  },
  filters: {
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
    dataformat2(value) {
      if (!value) return "";
      return dayjs.unix(value).format(" mm");
    },
  },
  methods: {
    getdata(item) {
      this.form.vr_id = item.id
      this.getmyvrstudylist();
    },
    //页码发生改变时
    pageChange(val) {
      this.form.page = val
      this.getmyvrstudylist();
    },
    resultsbt(row) {
      this.$refs.customdialog.dialogopenbt();
      this.info = row;
      let params = {
        vr_study_id: row.id,
      };
      getStepList(params)
        .then((response) => {
          this.stepInfo = response.data;

        })
        .catch((error) => {
          console.log(error);
        });
    },
    newadd(row,type) {
      this.$router.push({
        path: "/home/personalcenter/veditorreport",
        query: {
          vr_id: this.form.vr_id,
          vr_study_id: row.id,
          itemid: row.report_id,
          type: type
        },
      });
    },
    viewreport(item) {
      console.log("item", item)
      this.dialogTableVisible = true;
      this.getvrstudyreportinfo({
        id: item.report_id,
      });
    },
    getvrstudyreportinfo(params) {
      getvrstudyreportinfo(params)
        .then((response) => {
          if (response.data) {
            console.log("response.data", response.data)
            this.itemdetail = response.data;
           
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getmyvrstudylist() {
      getmyvrstudylist(this.form)
        .then((response) => {
          this.total = response.data.total;
          this.tableData = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 查看下载
    seeFiles(fileUrl){
      // let newUrl = this.$api.baseUrl + fileUrl
      let doc_ext = fileUrl.substring(fileUrl.lastIndexOf(".")+1)

      const typeArr = ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx']
      let url = '' // 接收文件链接 
      if (typeArr.indexOf(doc_ext) !== -1) {
        // 使用微软的office online
        // url = 'http://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(fileUrl)
        url = "https://view.xdocin.com/view?src=" + encodeURIComponent(fileUrl)
      } else {
        url = fileUrl
      }
      // window.open()打开
      window.open(url,"_blank")
    },
  },
};
</script>
<style scoped lang="scss">

.scoretable {
  .search {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    background: #fcfcfc;
    border: 1px solid #ececec;

    .leftcontain {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .left1 {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .text {
          margin-right: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #888888;
        }

        .elinput {
          width: 220px;

          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .left2 {
        margin-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .text {
          margin-right: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #888888;
        }

        .elselect {
          width: 100px;
        }
      }
    }

    .rightcontain {
      display: flex;
      justify-content: center;
      align-items: center;

      .bt {
        width: 64px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #3d84ff;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        cursor: pointer;
      }

      .bt1 {
        margin-right: 10px;
        background: #3d84ff;
        color: white;
      }
    }
  }

  .Table {
    padding: 10px;
    margin-top: 30px;
    width: 100%;

    .bt {
      font-size: 15px;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      cursor: pointer;
      padding: 0px;
      margin: 0px;
    }
  }

  .elpagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .elpagination-title {
      margin-right: 4px;
    }
  }
}

.title {
  color: #000;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
}

.head {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .item {
    margin-bottom: 15px;

    span {
      margin-right: 50px;
    }
  }
}

.content {
  .content_title {
    font-size: 26px;
    font-weight: bolder;
  }
}

.content_info {
  margin-top: 10px;

  .content_info_item {
    margin-bottom: 5px;

    .info_item_title {
      font-weight: bolder;
      margin-bottom: 15px;
      font-size: 16px;
    }

    .info_item_body {
      div {
        margin-bottom: 10px;
      }
    }
  }
}

.innerheadtitle {
  margin-left: 14px;
}

.innerdialogbody {
  border-top: 1px solid #cccccc;
  margin-top: 5px;
  min-height: 600px;
  padding: 20px;
}
.dialogcontain {
  padding: 10px 20px;

  .sectioncontain {
    width: 100%;

    .title {
      color: #222222;
      font-size: 18px;
      font-weight: 700;
      text-align: left;
      line-height: 2;
    }

    .content {
      color: #222222;
      font-size: 14px;
      line-height: 2;
    }

    .annex {
      margin: 10px 0;
    }
  }

  .dialogmain {
    .content {
      text-indent: 2em;
    }

    .annex {
      text-indent: 0;
    }
  }
}
</style>
