<template>
  <div class="item_contain">
    <div
      v-if="isshow"
      class="content"
      @mouseover="mouseOver(1)"
      @mouseleave="mouseLeave"
    >
      <div class="text" :style="{ top: '4px' }">
        {{ itemdata.course_lib_name + "(" + itemdata.course_lib_sn + ")" }}
      </div>
      <div class="text" :style="{ top: '20px' }">{{ itemdata.lab_room_name }}</div>
      <div class="text" :style="{ top: '40px' }">{{ itemdata.week_number+'周'}}</div>
      <div class="text" :style="{ top: '60px' }">{{ itemdata.teacher_name }}</div>
      <div v-if="isoverid == 1" class="overcontain">
        <div class="itemcontain">
          <el-image class="elimage" :src="icon3" />
          <span class="text22">{{
            itemdata.course_lib_name + "(" + itemdata.course_lib_sn + ")"
          }}</span>
        </div>
        <div class="itemcontain">
          <el-image class="elimage" :src="icon3" />
          <span class="text22">{{ itemdata.lab_room_name }}</span>
        </div>
        <div class="itemcontain">
          <el-image class="elimage" :src="icon3" />
          <span class="text22">{{ itemdata.week_number }}</span>
        </div>
        <div class="itemcontain">
          <el-image class="elimage" :src="icon3" />
          <span class="text22">{{ itemdata.teacher_name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemPage",
  components: {},

  props: {
    day: {
      type: Number,
    },
    itemdata: {
      type: Object,
      default: {},
    },
  },

  data() {
    return {
      courselist: "",
      info: "",
      isshow: true,
      isoverid: -1,
      icon3: require("@/assets/shiyan-03.png"),
    };
  },
  mounted() {
    console.log(this.itemdata, '----this.itemdata');
  },
  methods: {
    mouseOver(i) {
      this.isoverid = i;
    },
    mouseLeave() {
      this.isoverid = -1;
    },
  },
};
</script>
<style scoped lang="scss">
.item_contain {
  width: 100%;
  height: 100%;
  .content {
    // border: 1px solid red;
    width: 100%;
    height: 100%;
    padding: 0px;
    background: #d2f5f5;
    position: relative;
    .text {
      position: absolute;
      // border: 1px solid red;
      font-size: 12px;
      font-weight: 400;
      color: #303030;
      left: 10px;
      line-height: 12px;
    }
    .overcontain {
      position: absolute;
      z-index: 100;
      top: 100%;
      left: -40px;
      width: 300px;
      height: 185px;
      background: #ffffff;
      border: 1px solid #dddddd;
      border-left: 4px solid #2a7cec;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15),
        0px 0px 6px 0px rgba(0, 1, 1, 0.08);
      .itemcontain {
        margin-top: 20px;
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        .elimage {
          width: 20px;
          height: 20px;
        }
        .text22 {
          margin-left: 10px;
          height: 20px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
